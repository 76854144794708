// Required
@import "../node_modules/bootstrap/scss/functions";
$primary: #C8640A;
$white: #fff;
$navbar-dark-color: $white;
$navbar-dark-active-color: #202020;
$btn-font-family: "UntroubleCy ExtraBold", sans-serif;

$btn-border-radius: 6px;
$btn-padding-y: 0.25rem;
$green: #0A3737;
$blue: #0A233C;
$secondary: #1E7D87;
$untrouble-green-secondary: #1e7d87;
$input-border-radius: 0;

$dark: #202020;

$card-border-radius: 0;
$card-bg: $primary;
$card-border-width: 0;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1289px
);

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/root";

// Layout & components
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";
@import "fonts";

.workinparadise {
  letter-spacing: 0.5rem;
  font-size: 4.5rem;
}

.hero-header {
  background: linear-gradient(rgba(200, 100, 10, 0.4), rgba(200, 100, 10, 0.4)), url(../img/shutterstock_1034904322@2x.jpg?as=webp&width=2560);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
}

.hero-about {
  background: linear-gradient(rgba(30, 125, 135, 0.8), rgba(30, 125, 135, 0.8)), url(../img/stefan-stefancik-jyeSAci8bTw-unsplash.jpg?as=webp&width=2560);
  background-position: center center;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-locations {
  background: linear-gradient(rgba(30, 125, 135, 0.5), rgba(30, 125, 135, 0.5)), url(../img/il-vagabiondo-Bd47jWd-DuQ-unsplash.jpg?as=webp&width=2560);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-contact {
  background: linear-gradient(rgba(10, 35, 65, 0.69), rgba(10, 35, 60, 0.69)), url(../img/shutterstock_181008788@2x.jpg?as=webp&width=2560);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.container {
  .navbar {
    .nav-link {
      font-family: "UntroubleCy SemiBold", sans-serif;
      background-color: $white;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-right: 1.25rem;
      border-radius: 2px;
      color: $dark;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
        color: $untrouble-green-secondary;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  [id]::before {
    content: '';
    display: block;
    height:      58px;
    visibility: hidden;
  }

  #navbarCollapse::before {
    content: none;
  }

  .hero-header {
      .navbar {
        width: 100vw;
        background-color: transparentize($untrouble-green-secondary, 0.1) !important;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $zindex-fixed;
      }
  }

  .container {
    .navbar {
      .nav-link {
        margin-bottom: 0.5rem;
        margin-right: 0;
        background-color: $white !important;
        color: $untrouble-green-secondary !important;
      }
    }
  }
}

.btn-light {
  color: $primary;
}

.bg-green {
  background-color: $green;
}

.card {
  img {
    width: auto !important;
  }
}

fieldset legend {
  display: none;
}

form .form-group:nth-child(1),
form .form-group:nth-child(2),
form .form-group:nth-child(3) {
  border: 1px solid $white;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

form .form-group:nth-child(4) {
  border: 1px solid $white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

form > .form-group {
  display: none;
}

.border-bottom-white {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid $white;
}

.form-control:focus {
  box-shadow: none;
}

ul li, .text-shadow {
  text-shadow: 2px 3px 5px rgba(0,0,0,0.5);
}

ul li {
  margin-bottom: 0.625rem;
}

ul li:last-child {
  margin-bottom: 0;
}

.mb-4 label {
  padding: 1rem 0.75rem;
}

.form-select-wip.mb-4 label {
  padding-left: 0;
}

textarea::placeholder{
  color: #fff;
}

#message_text {
  box-sizing: border-box;
  max-height: 94px; /* optional */
  min-height: 31px;
}

.bg-grey {
  background: #EBEBEB;
}

.bg-untrouble-green-secondary {
  background-color: $untrouble-green-secondary !important;
}

.text-untrouble {
  color: $untrouble-green-secondary;
}
