@include media-breakpoint-down(sm) {
  :root {
    font-size: 80%;
  }
}

@font-face {
  font-family: "UntroubleCy Regular";
  src: url(../fonts/UntroubleCy-Regular.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "UntroubleCy Bold";
  src: url(../fonts/UntroubleCy-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "UntroubleCy ExtraBold";
  src: url(../fonts/UntroubleCy-ExtraBold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "UntroubleCy SemiBold";
  src: url(../fonts/UntroubleCy-SemiBold.woff2) format("woff2");
  font-display: swap;
}

body * {
  font-family: "UntroubleCy Regular", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "UntroubleCy SemiBold", sans-serif;
  color: #fff;
}

h3 {
  font-family: "UntroubleCy ExtraBold", sans-serif;
  font-size: 2rem;
}

h1.head {
  font-size: 5.4375rem;
  line-height: 4.5rem;
}

.fw-extra-bold {
  font-family: "UntroubleCy ExtraBold", sans-serif;
}

p {
  font-family: "UntroubleCy SemiBold", sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.wrap strong,
.wrap strong a,
.wrap strong span {
  font-family: "UntroubleCy Bold", sans-serif;
  font-weight: normal;
}

/* responsive
@media (max-width: 991.98px) {
  /*.intro {
    font-size: 1rem;
  }
} */

#untroubleNav {
  font-family: "UntroubleCy SemiBold", sans-serif;
}

footer {
  font-size: 0.875rem;
  font-family: "UntroubleCy SemiBold", sans-serif;
}

footer a {
  font-size: 0.875rem;
  text-decoration: none;
}

.locations {
  h5 {
    font-size: 1.5rem;
    margin: 0;
  }
}

.team,
.contact,
.hero-about {
  h2 {
    font-size: 2.75rem;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  h4 {
    color: #0A233C;
    font-size: 1.75rem;
  }
}


.contact {
  p {
    color: $white;
    line-height: 1.875rem;
  }
}

label {
  color: #fff;
}
